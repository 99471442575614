'use strict';
angular.module('angus.controllers').controller('delayedQuotesCtrl', [
    '$scope', '$http', '_', '$q', '$interval', '$rootScope',
    function($scope, $http, _, $q, $interval, $rootScope) {

        // $scope.data = [];
        // var reload;
        //
        // function symbolPrecision(symbol) {
        //     switch(symbol) {
        //         case 'RB':
        //         case 'RT':
        //         case 'HO':
        //             return 4;
        //         case 'NG':
        //             return 3;
        //         case '8K':
        //         case 'B0':
        //             return 5;
        //         default:
        //             return 2;
        //     }
        // }
        //
        // function hasTime(symbol) {
        //     switch(symbol) {
        //         case '8K':
        //         case 'B0':
        //             return false;
        //         default:
        //             return true;
        //     }
        // }
        //
        // function refreshInterval() {
        //     if(angular.isDefined(reload)) return;
        //
        //     reload = $interval(function() {
        //         fetchData();
        //     }, 60000);
        // }
        //
        // $scope.$on('$destroy', function(event) {
        //     $interval.cancel(reload);
        //     reload = undefined;
        // });
        //
        // function fetchData() {
        //     return $scope.widget.promises.monitor(
        //         $http({
        //             method: 'GET',
        //             url: ('/api/subscribers/{0}/commodityIndicies/delayedQuotes').format($rootScope.user.subscriberId),
        //             params: {
        //                 symbols: $scope.widget.instance.settings.commodityIndexSymbols.value
        //             }
        //         })
        //         .then(function(result) {
        //             refreshInterval();
        //
        //             if(!result.data) {
        //                 $scope.widget.noContentFlag = true;
        //
        //                 return;
        //             }
        //
        //             $scope.active = true;
        //             $scope.data = _.map(result.data, function(quote) {
        //                 var isChangeUp = quote.change > 0;
        //                 var isChangeDown = quote.change < 0;
        //                 var lastValue = quote.close;
        //
        //                 return {
        //                     success: quote.success,
        //                     name: quote.name,
        //                     date: quote.date,
        //                     time: quote.time,
        //                     symbol: quote.symbol,
        //                     close: quote.success ? '$' + quote.last.toFixed(symbolPrecision(quote.symbol)) : '$' + lastValue,
        //                     closeDir: quote.last > 0,
        //                     change: quote.success ? '$' + Math.abs(quote.change).toFixed(symbolPrecision(quote.symbol)) : '-',
        //                     isChangeUp: isChangeUp,
        //                     isChangeDown: isChangeDown,
        //                     isChangeZero: !isChangeUp && !isChangeDown,
        //                     showTime: hasTime(quote.symbol)
        //                 };
        //             });
        //
        //             $scope.widget.noContentFlag = false;
        //         }, function(err) {
        //             refreshInterval();
        //             $scope.widget.noContentFlag = true;
        //         }));
        // }
        //
        // $scope.widget.setDefaultInitialization(fetchData);
        //
        // $scope.isZeroThreshold = function(value) {
        //     return value.toFixed(4);
        // };
        //
        // $scope.$on('widgetSettingsChanged', function() {
        //     $interval.cancel(reload);
        //     reload = undefined;
        //
        //     fetchData();
        // });

    }
]);
