angular.module('angus.controllers').controller('marginComponentsSettingsCtrl', [
  '$scope', '$rootScope', '$http', 'datePickerService','periodIndicators', 'productsService', 'hierarchyService', 'tradeClassService', 'constantsService','budgetsService',
  function ($scope, $rootScope, $http, datePickerService, periodIndicators, productsService, hierarchyService, tradeClassService, constantsService,BudgetsService) {
    var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId

    function selectedCategory(categories, categoryIds){
        _.forEach(categories, function(category){
            category.selected = _.some(categoryIds, function(categoryId){ return category.id == categoryId });
        });
    }
    $scope.selected = {};
    var service = new BudgetsService(subscriberId);
    $scope.commodities = constantsService.commodityIndex.enums;
    $scope.selected.PstartDate = moment($scope.widget.instance.settings.startDate ).format('MMM DD, YYYY');
    $scope.selected.PendDate = moment($scope.widget.instance.settings.endDate ).format('MMM DD, YYYY');
    productsService.getProducts()
      .then(function (products) {
        $scope.products = products;
      });
      $scope.openStartDate = function(){
        datePickerService.openDatePickerModal(
          $scope.widget.instance.settings.startDate,
          $scope.widget.instance.settings.endDate,
            moment()
          )
          .then(function(resultDates) {
            //capital P dates are used for presentation only
            $scope.widget.instance.settings.startDate = resultDates[0]._d;
            $scope.selected.PstartDate = moment(resultDates[0]._d).format('MMM DD, YYYY');
            $scope.widget.instance.settings.endDate = resultDates[1]._d;
            $scope.selected.PendDate  = moment(resultDates[1]._d).format('MMM DD, YYYY');

          })
        }
      service.getPricePlans()
              .then(function(pricePlanCategories){
                pricePlanCategories.shift();
                selectedCategory(pricePlanCategories, $scope.widget.instance.settings.pricePlanCategoryIds ? $scope.widget.instance.settings.pricePlanCategoryIds.value : []);
                $scope.pricePlanCategories = pricePlanCategories;
              });

    $scope.openDivisionHierarchyView = function() {
      hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
        .then(function(nodeId) {
          $scope.widget.instance.settings.hierarchyNodeId = nodeId;
        });
    };
  }
]);
