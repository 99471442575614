angular.module('angus.controllers').controller('paygoUsageHistoryCtrl', [
	'$scope', 'fluentRest', '$q', 'promiseMonitor', '_', 'moment', 'Paging', 'csvService', 'modalService', 'actionViewTemplates',
	function($scope, fluentRest, $q, promiseMonitor, _, moment, paging, csvService, modalService, actionViewTemplates) {


		function getDealers(subscriberId){
			return fluentRest
					.api()
					.subscribers(subscriberId)
					.dealers()
					.get();
		}

		function getTankStatuses(){
			    return fluentRest
                    .api()
                    .containers()
                    .statuses()
                    .get();
		}

		function getTransactionTypes(){
			return fluentRest
                    .api()
                    .transactions()
                    .types()
                    .get();
		}


		function getParams(includePaging){

			var params = {
				d    		: $scope.selected.dealers,
				s    		: $scope.selected.statuses,
				t 			: $scope.selected.types,
				sd   		: $scope.dateRange.start,
				ed  	 	: $scope.dateRange.end,
				cnf  		: $scope.bosCustomer.name.first,
				cnl  		: $scope.bosCustomer.name.last,
				cid  		: $scope.bosCustomer.id,
				ctid 		: $scope.bosCustomer.tankId
			};

			if(includePaging)
				params.ascending = $scope.ascending;
				params.sortBy = $scope.sortBy;

			return params;
		}

		function getApiEndpoint(){
			return fluentRest
					.api()
					.subscribers()
					.usages()
					.history();
		}

		$scope.getUsageHistory = function(pageNumber){
			if($scope.pageMonitor.isPending())
				return;

			var params = getParams(true);

			var url = getApiEndpoint().toString();

			var promise =  $scope.paging.getPage(url, pageNumber, params)
								.then(function(history){
									$scope.history = history.items;
								});

			$scope.pageMonitor.monitor(promise);

			return promise;
		}

		function init(){
			$scope.promiseMonitor = new promiseMonitor();
			$scope.pageMonitor = new promiseMonitor();

			$scope.paging = new paging(25);
			$scope.ascending = true;
			$scope.sortBy = 'transaction.date';

			$scope.dateRange = {
				start : moment().subtract(30, 'days').toDate(),
				end   : moment().toDate()
			};

			$scope.bosCustomer = {
				id : '',
				tankId : '',
				name :{
					first : '',
					last : ''
				}
			};

			$scope.selected = {};

			var promise = $q.all([
							getDealers($scope.subscriberId)
								.then(function(dealers){
									$scope.dealers = dealers;
									$scope.selected.dealers = _.map($scope.dealers, 'id');

								}),
							getTankStatuses()
								.then(function(statuses){
									$scope.containerStatuses = statuses;
									$scope.selected.statuses = _.map(statuses, 'id');
								}),
							getTransactionTypes()
								.then(function(types){
									$scope.transactionTypes = types;
									$scope.selected.types = _.map(types, 'id');
								})
					])
					.then(function(){
						return $scope.getUsageHistory();
					});

			$scope.promiseMonitor.monitor(promise);
		}

		$scope.sort= function(sortBy){

			if($scope.sortBy == sortBy)
				$scope.ascending = !$scope.ascending;
			else {
				$scope.ascending = true;
				$scope.sortBy = sortBy;
			}

			return $scope.getUsageHistory(1);
		}

		 function stopEvent($event){
            $event.preventDefault();
            $event.stopPropagation();
        }


        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        $scope.startDateOpened = false;
        $scope.endDateOpened = false;

        $scope.openStartDate= function($event){
            stopEvent($event);
            this.endDateOpened = false;
            this.startDateOpened = true;
        };
        $scope.openEndDate= function($event){
            stopEvent($event);
            this.startDateOpened = false;
            this.endDateOpened = true;
        };
        $scope.resetEndDate = function(){
            if(moment(this.dateRange.end).isBefore(moment(this.dateRange.start)))
                this.dateRange.end = this.dateRange.start;
        };

        function getCustomerById(id, subscriberId){
			return fluentRest
					.api()
					.subscribers(subscriberId)
					.customerAccounts(id)
					.paygo()
					.get();
		}

		$scope.openCustomer = function(usage, navigateToContainer){

			getCustomerById(usage.customer.navigationId, usage.dealer.subscriberId)
				.then(function(customer){
					if(customer){

						var widget, template; 
						if(!$scope.subscriberId){
							widget 	 = constants.widgets.paygoAdminCustRec;
							template = actionViewTemplates.paygoAdminCustRec;
						} else{
							widget = constants.widgets.paygoCustomers;
							template = actionViewTemplates.paygoCustRec;
						} 

						var scope = {
							widgetName : 'Paygo Customer Records',
							widgetIcon : 'group',
							widgetId   : widget.value.id,
							widgetCode : widget.value.code,
							model      : { 
								customer : customer, 
								containerId : navigateToContainer ? usage.customer.tankNavigationId : null								
							}
						};

						modalService
							.openActionView( template, scope, widget.value.id );
					}
				});

		};


        $scope.downloadInProgress = csvService.downloadInProgress;

        $scope.toCsv = function(){
        	var params = getParams();
			var url = getApiEndpoint().toString();

        	csvService
        		.getCsv(url, params, 'Consumer Usage History Report (' + moment().format('l') + ').csv');
        };



		init();
	}
]);
