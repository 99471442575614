angular.module('angus.controllers').controller('delayedQuotesSettingsCtrl', [
    '$scope', '$http', '$rootScope',
    function($scope, $http, $rootScope) {
        'use strict';
        $scope.widget.instance.settings = {};
        // var subscriberId = $scope.susbcriberId = $rootScope.user.subscriberId;
        //
        // return $http({
        //     url: ('api/subscribers/{0}/costing/indices').format(subscriberId),
        //     method: 'GET'
        // })
        // .then(function(response) {
        //     $scope.commodities = response.data.filter(function(commodity) {
        //         return commodity.flag == 1;
        //     });
        //
        //     $scope.widget.instance.settings.commodityIndexSymbols.value = $scope.widget.instance.settings.commodityIndexSymbols.value || [];
        //
        //     return response;
        // });
    }
]); 