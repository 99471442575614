angular.module( 'angus.directives' ).directive( 'marginComponents', [
    'actionViewTemplates',
    function( actionViewTemplates ) {
        return {
            scope: {
                model: "=actionModel",
                settings: "=marginComponentsWidgetSettings",
                widgetCode : "="
            },
            templateUrl: actionViewTemplates.marginComponentsDefault
        };
    }
] );