angular.module('angus.controllers').controller('paygoMonthlyAccountingCtrl', [
	'$scope', 'fluentRest', '$q', 'promiseMonitor', '_', 'moment', 'Paging', 'csvService', 'modalService', 'actionViewTemplates',
	function($scope, fluentRest, $q, promiseMonitor, _, moment, paging, csvService, modalService, actionViewTemplates) {


		function getDealers(subscriberId){
			return fluentRest
					.api()
					.subscribers(subscriberId)
					.dealers()
					.get();
		}

		function getParams(includePaging){
			var params = {
				d    		: $scope.selected.dealers,
				m    		: $scope.month,
				y   		: $scope.year
			};

			if(includePaging)
				params.ascending = $scope.ascending;
				params.sortBy = $scope.sortBy;

			return params;
		}

		function getApiEndpoint(){
			return fluentRest
					.api()
					.subscribers()
					.deliveries()
					.monthly();
		}

		$scope.getMonthlyDeliveries = function(pageNumber){
			if($scope.pageMonitor.isPending())
				return;

			var params = getParams(true);

			var url = getApiEndpoint().toString();

			var promise =  $scope.paging.getPage(url, pageNumber, params)
								.then(function(deliveries){
									$scope.deliveries = deliveries.items;
								});

			$scope.pageMonitor.monitor(promise);

			return promise;
		}

		var months = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];

		$scope.updateMonths = function() {
			var today = moment();
			$scope.months = $scope.year == today.year()
								? months.slice(0, today.month() + 1 )
								: months;

			$scope.month = $scope.month || today.month() + 1;

			if(!_.some($scope.months, function(month){
				return month == $scope.month;
			}))
				$scope.month = 1;

		};

		function init() {
			$scope.promiseMonitor = new promiseMonitor();
			$scope.pageMonitor    = new promiseMonitor();

			$scope.paging = new paging(25);
			$scope.ascending = false;
			$scope.sortBy = 'date';

			$scope.selected = {};

			$scope.year  = moment().year();
			$scope.years = [];

			for(var i =0; i < 10;  i++)
				$scope.years.push($scope.year - i );

			$scope.updateMonths();

			var promise = $q.all([
							getDealers($scope.subscriberId)
								.then(function(dealers){
									$scope.dealers = dealers;
									$scope.selected.dealers = _.map($scope.dealers, 'id');

								})
					])
					.then(function(){
						return $scope.getMonthlyDeliveries();
					});

			$scope.promiseMonitor.monitor(promise);
		}

		$scope.sort = function(sortBy) {

			if($scope.sortBy == sortBy)
				$scope.ascending = !$scope.ascending;
			else {
				$scope.ascending = true;
				$scope.sortBy = sortBy;
			}

			return $scope.getMonthlyDeliveries(1);
		};

		function getCustomerById(id, subscriberId){
			return fluentRest
					.api()
					.subscribers(subscriberId)
					.customerAccounts(id)
					.paygo()
					.get();
		}

		$scope.openCustomer = function(delivery, navigateToContainer){

			getCustomerById(delivery.customer.navigationId, delivery.dealer.subscriberId)
				.then(function(customer){
					if(customer){

						var widget, template;
						if(!$scope.subscriberId){
							widget 	 = constants.widgets.paygoAdminCustRec;
							template = actionViewTemplates.paygoAdminCustRec;
						} else{
							widget = constants.widgets.paygoCustomers;
							template = actionViewTemplates.paygoCustRec;
						}

						var scope = {
							widgetName : 'Paygo Customer Records',
							widgetIcon : 'group',
							widgetId   : widget.value.id,
							widgetCode : widget.value.code,
							model      : {
								customer : customer,
								containerId : navigateToContainer ? usage.customer.tankNavigationId : null
							}
						};

						modalService
							.openActionView( template, scope, widget.value.id );
					}
				});

		};

		 $scope.downloadInProgress = csvService.downloadInProgress;

		 $scope.toCsv = function(){
        	var params = getParams();
			var url = getApiEndpoint().toString();

        	csvService
        		.getCsv(url, params, 'Monthly Accounting Report (' + moment().format('l') + ').csv');
        };



		init();
	}
]);
