angular.module('angus.controllers').controller('serviceContractsProfileContainerCtrl', [
    '$scope', '$rootScope', '$q', '_', 'drillService', 'promiseMonitor', '$http', 'fluentRest', 'gridService2', 'gridState', 'nodeType',
    function($scope, $rootScope, $q, _,  drillService, PromiseMonitor, $http, fluentRest, gridService2, gridState, nodeType) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.filter = {
            divisionId 		     : $scope.settings.hierarchyNodeId,
            covered              : true,
            uncovered            : false,
            serviceContractTypes : $scope.settings.serviceContractTypes ? $scope.settings.serviceContractTypes.value : [],
            activeContractsCount : $scope.settings.activeContractsCount ? $scope.settings.activeContractsCount : false,
            coveredSystemsCount  : $scope.settings.coveredSystemsCount ? $scope.settings.coveredSystemsCount : false,
        };

        $scope.getServiceContracts = function() {
            $scope.grid.setColDefs(getColDefs());

            var rowPromise = $http({
                method: 'POST',
                url: 'api/subscribers/{0}/serviceContracts/'.format($rootScope.user.subscriberId),
                data: $scope.filter
            })
                .then(function(result) { 
                    $scope.activeContractsOrCoveredSystems = [];

                    _.forEach(result.data, function(contracts) {
                        $scope.activeContractsOrCoveredSystems.push(contracts);

                        contracts.serviceContractEndYear = contracts.serviceContractEndDate;
                        contracts.serviceContractEndMonth = contracts.serviceContractEndDate;

                        contracts.mostRecentTuneUpServiceYear = contracts.mostRecentTuneUpServiceDate;
                        contracts.mostRecentTuneUpServiceMonth = contracts.mostRecentTuneUpServiceDate;
                    });

                    return result.data;
                });

            var promise = $scope.grid.setRows(rowPromise);
            $scope.monitor = new PromiseMonitor(promise);

            return promise;
        };

        function getColClicks() {
            return{
                accountNumber : {
                    onClick : function(row) {
                        drillService.openAccountInfo(row);
                    }
                },
                locationNumber    : {
                    onClick : function(row){ drillService.openServiceLocationInfo(row); }
                },
            };
        }

        function getColDefs() {
            // var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];

            colDefs.push(gridService2.colDef.createDate('serviceContractEndDate', 'Contract Expiration Date', 'serviceContractEndDate', { sort : 'asc'}));
            colDefs.push(gridService2.colDef.createYear('serviceContractEndYear', 'Contract Expiration Year', 'serviceContractEndYear', { hide: true }));
            colDefs.push(gridService2.colDef.createMonth('serviceContractEndMonth', 'Contract Expiration Month', 'serviceContractEndMonth', { hide: true }));
            colDefs.push(gridService2.colDef.createText('customerName', 'Customer Name', 'customerName', { sort : 'asc'}));
            colDefs.push(gridService2.colDef.createText('accountNumber', 'Account Number', 'accountNumber', { cellClass : ['center']}));
            colDefs.push(gridService2.colDef.createText('locationNumber','Service Location','locationNumber'));
            colDefs.push(gridService2.colDef.createText('serviceEquipmentName', 'Equipment', 'serviceEquipmentName'));
            colDefs.push(gridService2.colDef.createText('serviceContractDesc', 'Contract', 'serviceContractDesc'));
            colDefs.push(gridService2.colDef.createDate('mostRecentTuneUpServiceDate', 'Last Tune-Up Date', 'mostRecentTuneUpServiceDate', {}));
            colDefs.push(gridService2.colDef.createYear('mostRecentTuneUpServiceYear', 'Last Tune-Up Year', 'mostRecentTuneUpServiceYear', { hide: true }));
            colDefs.push(gridService2.colDef.createMonth('mostRecentTuneUpServiceMonth', 'Last Tune-Up Month', 'mostRecentTuneUpServiceMonth', { hide: true }));

            return colDefs;
        }

        var nodeColDefs;
        nodeType
            .getDivisionColDefs(subscriberId)
            .then(function(colDefs) {
                nodeColDefs = colDefs;
                var gridParams = {
                    gridOptions: {
                        groupAggFunction: function(rows) {
                            return _.reduce(rows, function(result, row) {

                                function construct(grouping) {
                                    var groupings = _.reduce(row.children, function(groups, child) {
                                        groups[child.data['' + grouping]] = true;


                                        child.keys = [child.data['' + grouping]];

                                        return groups;
                                    }, {});

                                    row.keys =  _.keys(groupings);
                                    row.allChildrenCount = row.keys.length;

                                    //traverse up
                                    var current = row;

                                    for(var index = 0; index < row.level; index++) {
                                        current = current.parent;
                                        current.items = [];

                                        current.allChildrenCount = 0;
                                        current.keys = [];

                                        _.forEach(current.children, function(leaf) {
                                            if(!leaf.keys) {
                                                return;
                                            }

                                            for (var _index = 0; _index < leaf.keys.length; _index++) {

                                                if (!current.keys.includes(leaf.keys[_index])) {
                                                    current.keys.push(leaf.keys[_index]);
                                                }
                                            }

                                        }, {});

                                        current.allChildrenCount = current.keys.length;
                                    }

                                }

                                if (row.children
                                    && row.children.length > 0
                                    && !row.children[0].group) {

                                    if ($scope.filter.activeContractsCount) {
                                        construct("serviceContractKey");
                                    }
                                    if ($scope.filter.coveredSystemsCount) {
                                        construct("serviceEquipmentKey");
                                    }

                                }

                                return result;
                            }, {})
                        }
                    },
                    gridState     : gridState(subscriberId, $scope.widgetCode),
                    defs          : getColDefs(),
                    clicks        : getColClicks(),
                    exportOptions : { fileName: 'Company Profile - Service Contracts' }

                };

                return gridService2.createGrid(gridParams);
            })
            .then(function(grid) {
                $scope.grid = grid;
                $scope.getServiceContracts();
            });
    }
]);