angular
  .module('angus.controllers')
.controller('etlStatusCtrl', ['$http', '$scope', 'Paging', 'promiseMonitor', 'fluentRest', '_','moment', function($http, $scope, Paging, PromiseMonitor, fluentRest, _, moment){
    'use strict';

    $scope.sortBy = 'lastExecutionStatusKey';
    $scope.ascending = false;

    $scope.paging = new Paging($scope.pageSize || 200);

    $scope.promiseMonitor = $scope.widget ? $scope.widget.promises : new PromiseMonitor();
    $scope.pagingMonitor  = new PromiseMonitor();

    function init() {
      return $scope.promiseMonitor.monitor($scope.getEtlStatus());
    }

    $scope.getEtlStatus = function(pageNumber) {

      var params = {
          sortBy   : $scope.sortBy,
          ascending: $scope.ascending,
          includePendingExecutions: _.get($scope, ['widget', 'instance', 'settings', 'includePendingExecutions'], false)
      };

      var url = fluentRest.api().subscribers().etl().toString();

      var promise = $scope.paging.getPage(url, pageNumber, params)
        .then(function (etlStatus) {
          $scope.etlStatus = _.map(etlStatus.items, function (item) {
            item.lastSuccessOn = moment.utc(item.lastSuccessOn).format('lll');
            return item;
          });
        });

      $scope.pagingMonitor.monitor(promise);

      return promise;
    }


    $scope.sort = function(sortBy) {
      if ($scope.sortBy == sortBy) $scope.ascending = !$scope.ascending;
      else {
        $scope.sortBy = sortBy;
        $scope.ascending = true;
      }
      return $scope.getEtlStatus();
    };

    $scope.$on('widgetSettingsChanged', function() {
      init();
    });


    //Load up the widget
    $scope.widget.setDefaultInitialization(init);
  }]);
