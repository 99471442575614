angular.module( 'angus.directives' ).directive( 'paygoTankMonitorPerformance', [
    'actionViewTemplates',
    function( actionViewTemplates ) {
        return {
            scope: {
                model: "=actionModel",
                settings: "=paygoTankMonitorPerformanceWidgetSettings",
                widgetCode : "="
            },
            templateUrl: actionViewTemplates.paygoTankMonitorPerformanceActionView
        };
    }
] );