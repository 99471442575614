angular.module('angus.directives').directive('paygoCustRec',
    [
        '$document',
        function ($document) {
            return {
                scope: {
                    closeCb: "=",
                    actionModel: "=",

                },
                controller: function ($scope) {
                    $scope.customer = $scope.actionModel.customer || null;
                    $scope.containerId = $scope.actionModel.containerId || null;
                    $scope.close = function () {
                        if ($scope.modalClosedCb) $scope.modalClosedCb();
                        if ($scope.closeCb) $scope.closeCb();
                    };


                },
                templateUrl: 'templates/widgets/paygoCustRec/html/actionViews/paygoCustRecActionView.html',
                link: function (scope, element, attrs, ctrl) {
                    if (element.data('isAdmin')) scope.actionModel.isAdmin = true;
                }
            };
        }
    ]).directive('flotLine', [
        '$filter',
        function ($filter) {
            return {
                scope: {
                    ngModel: "=",
                    months: "="
                },
                link: function (scope, element, attr) {
                    function plot(usages) {

                        var colors = [
                            "rgba(0,153,211, .75)",
                            "rgba(73,191,103, .75)",
                            "rgba(248,163,38 ,.75)",
                            "rgba(149,100,226, .75)",
                            "rgba(243,69,38, .75)"
                        ];

                        var tickSize = [1, "month"];

                        if (scope.months < 1.5) {
                            tickSize = [7, "day"];
                        } else if (scope.months > 12) {
                            tickSize = [1, "quarter"];
                        } else if (scope.months > 24) {
                            tickSize = [1, "year"];
                        }



                        var options = {
                            xaxis: {
                                mode: "time",
                                tickSize: tickSize,
                            },
                            grid: {
                                hoverable: true,
                                clickable: false,
                                borderWidth: 0
                            },
                            legend: {
                                show: false,
                                labelBoxBorderColor: "none",
                                position: "left"
                            },
                            series: {
                                shadowSize: 1
                            },
                            tooltip: true,
                            tooltipOpts: {
                                defaultTheme: false,
                                content: "%y units"
                            }
                        };

                        $.plot(element, [usages], options);

                    }

                    scope.$watch(function () {
                        return scope.ngModel;
                    }, function (newVal) {
                        if (newVal)
                            plot(newVal);
                    });


                }
            };
        }
    ]);
