angular.module('angus.controllers').controller('paygoTankMonitorPerformanceActionViewCtrl', [
    '$scope', '$rootScope', '$q', '$http', 'dateCalculator', 'constantsService', 'restrictedAccessService', 'gridService2', 'gridState', 'actionViewTemplates', 'drillService', 'promiseMonitor', 'nodeType', 'moment', '_',
    function ($scope, $rootScope, $q, $http, dateCalculator, constantsService, restrictedAccessService, gridService2, gridState, actionViewTemplates, drillService, PromiseMonitor, nodeType, moment, _) {
        'use strict';

        $scope.periodIndicators = constantsService.paygoPeriodIndicators.enums;
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        var dates = dateCalculator.calculatePeriod(
            moment().add(-1, 'years'),
            moment.utc(),
            !$scope.settings.periodIndicatorId ? null : $scope.settings.periodIndicatorId.value
        );

        if($scope.settings.customDate) {
            $scope.dates = {
                startDate: moment.utc($scope.settings.customDate.startDate).format('L') || moment(),
                endDate: moment.utc($scope.settings.customDate.endDate).format('L') || moment()
            }
        } else {
            $scope.dates = {
                startDate: dates.startDate,
                endDate: dates.endDate,
                lastInvoiceDate: dates.endDate
            };
        }


        $scope.filter = {
            startDate: $scope.dates.startDate,
            endDate: $scope.dates.endDate,
            divisionId: $scope.settings.hierarchyNodeId,
            startSize: $scope.settings.minTankSize ? $scope.settings.minTankSize.value : 275,
            endSize: $scope.settings.maxTankSize ? $scope.settings.maxTankSize.value : 275,
            productIds: $scope.settings.productIds ? $scope.settings.productIds.value : []
        };

        $scope.getContainerPerformance = function() {
            $scope.grid.setColDefs(getColDefs());
            var rowPromise = $http({
                method: 'POST',
                url: ('api/subscribers/{0}/containers/detailPerformance').format($rootScope.user.subscriberId),
                data: $scope.filter
            })
            .then(function(results) {
                return results.data;
            });

            var promise = $scope.grid.setRows(rowPromise);
            $scope.monitor = new PromiseMonitor(promise);

            return promise;
        };

        function getColDefs() {
            var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];

            colDefs.push(gridService2.colDef.createText('productName', 'Product', 'productName', { rowGroupIndex: nextIndex, hide: true}));
            colDefs.push(gridService2.colDef.createDate('deliveryDate', 'Delivery Date', 'deliveryDate', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createText('customerId', 'Customer ID', 'customerId', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createText('customerName', 'Customer Name', 'customerName', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createText('tankId', 'Tank ID', 'tankId', { cellClass: ['center'] }));

            colDefs.push(gridService2.colDef.createNumber('inTankPriorToDelivery', 'In Tank Prior To Delivery', 'inTankPriorToDelivery', { decimalPlaces: 4 }));
            colDefs.push(gridService2.colDef.createNumber('optimalDrop', 'Average Optimal Drop', 'optimalDrop', {}, { decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createNumber('delivery', 'Average Delivery', 'delivery', {}, { decimalPlaces: 4 }));
            colDefs.push(gridService2.colDef.createPercentage('variance', 'Average Variance', 'variance', {}));

            colDefs.push(gridService2.colDef.createBoolean('tankMonitorFlag', 'Tank Monitor', 'tankMonitorFlag', { cellClass: ['center'] }));

            return colDefs;
        }

        var nodeColDefs;
        $q.all([
            nodeType.getDivisionColDefs(subscriberId),
            restrictedAccessService.getAccess()
        ])
        .spread(function(colDefs, access) {
            $scope.restrictedAccess = access.restrictedAccess;
            nodeColDefs = colDefs;
            var gridParams = {
                gridState     : gridState(subscriberId, $scope.widgetCode),
                defs          : getColDefs(),
                exportOptions : { fileName: 'Paygo Tank Monitor Performance' }
            };
            return gridService2.createGrid(gridParams);
        })
        .then(function(grid) {
            $scope.grid = grid;
            $scope.getContainerPerformance();
        });
    }
]);