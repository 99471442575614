angular.module('angus.services').service('hierarchyHelperService', [
    '$rootScope', '_',
    function($rootScope, _) {

        'use strict';


        function convertDataForEaseOfUse(nodes) {

            var convertedNodes = _.cloneDeep(nodes);

            _(convertedNodes).forEach(function(node) {

                var parentObjList = [];
                _(node.parents).forEach(function(parentId) {
                    var parent = _(convertedNodes).find(function(d) {
                        return d.id == parentId;
                    });

                    if (parent) {
                        if (parent.children) parent.children.push(node);
                        else parent.children = [node];
                        parent.children.sort();
                        parentObjList.push(parent);
                    }
                });
                node.name = node.nameOverride || node.name;
                node.parents = parentObjList;
                node.parents.sort();
            });

            return convertedNodes;
        }

        function getDescendants(nodes, node, childrenList) {
            if (!childrenList) childrenList = [];

            if(!node) return nodes;
                _(node.children).forEach(function(child) {
                    if (!_(childrenList).some(function(inList) {
                        return inList == child;
                    })) {
                        childrenList.push(child);
                        childrenList = getDescendants(nodes, child, childrenList);
                    }
                });

            return childrenList;
        }

        function getAncestors(nodes, node, parentList) {
            if (!parentList) parentList = [];


            _(node.parents).forEach(function(parent) {
                if (!_(parentList).some(function(parentInList) {
                        return parentInList == parent;
                    })) {
                    parentList.push(parent);
                    parentList = getAncestors(nodes, parent, parentList);
                }
            });
            return parentList;
        }

        function getDistanceFromLeaf(nodeList, nodeId) {

            function getDistanceFromLeafRecursive(node) {
                var maxChildDepth = 0;
                _.forEach(node.children, function(child) {
                    var childDepth = getDistanceFromLeafRecursive(child);
                    if (childDepth > maxChildDepth) maxChildDepth = childDepth;
                });

                return maxChildDepth + 1;
            }

            var nodes = convertDataForEaseOfUse(nodeList);
            var node = _.find(nodes, function(node) {
                return node.id == nodeId;
            });

            var totalDistance = 0;
            if(node)
                totalDistance = getDistanceFromLeafRecursive(node);

            return totalDistance;
        }


        function getLeafNodeEntityIdsFromCollection(nodes, collection) {
          return  _.uniq(_.flatten(collection.map(function(nodeId) {
            return getLeafNodeEntityIds(nodes, nodeId);
          })));
        }

        function getLeafNodeEntityIds(nodes, nodeId) {
            if(Array.isArray(nodeId)) {
              return getLeafNodeEntityIdsFromCollection(nodes, nodeId);
            }

            var nodesWeCareAbout = nodes;
            if (nodeId) {
                var node = _(nodes).find(function(n) {
                    return n.id == nodeId;
                });

                nodesWeCareAbout = [node];
                nodesWeCareAbout = getDescendants(nodes, node, nodesWeCareAbout);
            }

            return _(nodesWeCareAbout).filter(function(n) {
                return n.isLeaf && n.entityId;
            }).map(function(n) {
                return n.entityId;
            }).value();

        }

        function markDealerAncestry(nodes) {
            _.forEach(nodes, function(node) {
                node.isInDealerHierarchy = false;
                if (node.intacctId) {
                    node.isInDealerHierarchy = true;
                    node.isDealer = true;
                } else {
                    var ancestors = getAncestors(nodes, node);
                    if (_.some(ancestors, function(a) {
                            return !!a.intacctId;
                        })) {
                        node.isInDealerHierarchy = true;
                        node.isUnderDealer = true;
                    } else {
                        var descendants = getDescendants(nodes, node);
                        if (_.some(descendants, function(d) {
                                return !!d.intacctId;
                            })) {
                            node.isInDealerHierarchy = true;
                        }
                    }
                }
            });

            return nodes;

        }


        return {

            getDescendants: getDescendants,
            getDistanceFromLeaf: getDistanceFromLeaf,
            getLeafNodeEntityIds: getLeafNodeEntityIds,
            convertDataForEaseOfUse: convertDataForEaseOfUse,
            markDealerAncestry: markDealerAncestry
        };
    }
]);
