angular.module('angus.controllers').controller('paygoTankMonitorPerformanceCtrl', [
    '$scope', '$rootScope', 'fluentRest', 'moment', 'dateCalculator', '_', 'constantsService', 'datePickerService',
    function ($scope, $rootScope, fluentRest, moment, dateCalculator, _, constantsService, datePickerService) {
        'use strict';

        $scope.periodIndicators = constantsService.paygoPeriodIndicators.enums;
        $scope.periodIndicator = _.find($scope.periodIndicators, function (period) {
            return $scope.widget.instance.settings.periodIndicatorId == period;
        });

        $scope.tooltip = {
            showTooltip: false,
            tipDirection: 'bottom'
        };

        function getContainerPerformance() {

            var params = {
                productIds: $scope.widget.instance.settings.productIds ? $scope.widget.instance.settings.productIds.value : [],
                startDate: $scope.dates.startDate.format(),
                endDate: $scope.dates.endDate.format(),
                startSize: $scope.widget.instance.settings.minTankSize ? $scope.widget.instance.settings.minTankSize.value : 275,
                endSize: $scope.widget.instance.settings.maxTankSize ? $scope.widget.instance.settings.maxTankSize.value : 275
            };

            return fluentRest
                .api()
                .subscribers($rootScope.user.subscriberId)
                .containers()
                .performance()
                .get(params)
                .then(function(response) {
                    $scope.performance = response;
                })
        }

        function init() {
            // for some reason, when adding fresh widget, it is added without a period indicator selected, and as a result,
            // period indicator cannot read a value of nothing, so an error is thrown and the widget face shows code.
            // setting an initial time period on initial widget add / load fixes this issue, and does not cause other issues
            if(!$scope.widget.instance.settings.periodIndicatorId) {
                $scope.widget.instance.settings.periodIndicatorId = {
                    value: 'priorMonth'
                };
            }

            /* Setup Dates */
            $scope.dates = {};

            var dates = dateCalculator.calculatePeriod(
                $scope.dates.startDate, moment.utc(),
                $scope.widget.instance.settings.periodIndicatorId.value,
                !$scope.widget.instance.settings.periodIndicatorId ? null : $scope.widget.instance.settings.periodIndicatorId.value
            );

            $scope.dates.startDate = !$scope.widget.instance.settings.customDate ? dates.startDate : $scope.widget.instance.settings.customDate.startDate;
            $scope.dates.endDate = !$scope.widget.instance.settings.customDate ? dates.endDate : $scope.widget.instance.settings.customDate.endDate;

            $scope.widget.instance.settings.displayDate =
                !$scope.widget.instance.settings.customDate ?
                    moment($scope.dates.startDate).format('MMM DD, YYYY') + ' to ' + moment($scope.dates.endDate).format('MMM DD, YYYY') :
                    moment($scope.widget.instance.settings.customDate.startDate).format('MMM DD, YYYY') + ' to ' + moment($scope.widget.instance.settings.customDate.endDate).format('MMM DD, YYYY');

            return $scope
                .widget
                .promises
                .monitor(getContainerPerformance())
        }

        $scope.widget.setDefaultInitialization(init);
        $scope.$on('widgetSettingsChanged', init);

        function getDateTooltipText(){
            var start = $scope.dates.startDate ? $scope.dates.startDate.format('L') : '';
            var end = $scope.dates.endDate ? $scope.dates.endDate.format('L') : '';

            return start + (!!start && !!end ? ' - ' : '') + end;
        }

        $scope.openDatePicker = function() {
            return datePickerService.openDatePickerModal(
                $scope.dates.startDate,
                $scope.dates.endDate,
                moment())
                .then(function(resultDates){
                    debugger;
                    $scope.dates.startDate = moment(resultDates[0].isValid() ? resultDates[0] : resultDates[1]);
                    $scope.dates.endDate = resultDates[1];

                    $scope.widget.promises.monitor(getContainerPerformance());

                    if($scope.widget.instance.settings.periodIndicatorId.value == constantsService.paygoPeriodIndicators.customDateRange) {
                        $scope.periodIndicator = constantsService.paygoPeriodIndicators.customDateRange;
                    }
                });
        };

    }
]);