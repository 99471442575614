angular.module('angus.controllers').controller('paygoTankMonitorPerformanceSettingsCtrl', [
  '$scope', '$rootScope', '$q', 'constantsService', 'productsService', 'hierarchyService', '_',
  function ($scope, $rootScope, $q, constantsService, productsService, hierarchyService, _) {
    'use strict';

    $scope.periodIndicators = constantsService.paygoPeriodIndicators.enums;

    if (typeof $scope.widget.instance.settings.periodIndicatorId === 'undefined') {
      $scope.widget.instance.settings.periodIndicatorId = {value: constantsService.paygoPeriodIndicators.prior12Months.key};
      $scope.widget.instance.settings.minTankSize = {value: 275};
      $scope.widget.instance.settings.maxTankSize = {value: 275};
    }

    productsService.getProducts()
      .then(function (products) {
        $scope.products = products;
      });

      $scope.openDivisionHierarchyView = function() {
          hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
              .then(function(nodeId) {
                  $scope.widget.instance.settings.hierarchyNodeId = nodeId;
              });
      };

  }
]);
