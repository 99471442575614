angular.module( 'angus.controllers' ).controller( 'marginComponentsCtrl', [
    '$scope','$rootScope', '$q', '$http', 'moment',  '_', 'hierarchyService', 'productsService', 'budgetsService',
    function( $scope,$rootScope, $q, $http, moment,  _, hierarchyService, productsService, BudgetsService ) {

        'use strict';

        var service = new BudgetsService(subscriberId);
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.graphData = {};

        $scope.colors = {
            color1: '#42d7f4',
            color2: '#f76342',
            color3: '#60e534',
        };

        $scope.maxIndex = 0;
        $scope.maxUP = 0;
        $scope.maxUC = 0;

        var maxNum = function(item) {
            if($scope.maxIndex < item.IndexPrice) {
                $scope.maxIndex = item.IndexPrice
            }
            if($scope.maxUP < item.UnitPriceAverage) {
                $scope.maxUP = item.UnitPriceAverage
            }
            if($scope.maxUC < item.UnitCostAverage) {
                $scope.maxUC = item.UnitCostAverage
            }

            var maxNum =  Math.max($scope.maxIndex, $scope.maxUP, $scope.maxUC);
            return [maxNum, (maxNum * .66), (maxNum * .33), 0]
        };

        $scope.graphData.axis = {};
        $scope.endDate = $scope.widget.instance.settings.endDate = moment().format('YYYY-MM-DD');
        $scope.startDate = $scope.widget.instance.settings.startDate = moment($scope.endDate).subtract(30, 'days').format('YYYY-MM-DD');

        Promise.all([
            productsService.getProducts()
            .then(function(products) {
                $scope.products = products.map(function(p) { return p.id });
            }),
            service.getPricePlans()
            .then(function (pricePlans) {
                pricePlans.shift();
                $scope.pricePlans = pricePlans.map(function(item) { return item.abosKeys[0] });
            })
        ]).then(function() {
            init();
        });

        $scope.$on('widgetSettingsChanged', function() {
            $scope.products = $scope.widget.instance.settings.productIds.value;
            init();
        });

        $scope.widget.setDefaultInitialization(init);

        function init() {
            $scope.nodata = false;
            $scope.divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
            var actualProducts = $scope.widget.instance.settings.productIds ? $scope.widget.instance.settings.productIds.value : $scope.products;

            $scope.graphData.axis.dates = {
                startDate:$scope.startDate,
                endDate:$scope.endDate
            };

            var config = {
                subscriberId: subscriberId,
                divisions: $scope.divisions,
                products: actualProducts,
                startDate: $scope.widget.instance.settings.startDate || $scope.startDate,
                endDate: $scope.widget.instance.settings.endDate || $scope.endDate,
                costIndex: $scope.widget.instance.settings.commodityIndexSymbols,
                pricePlans: [$scope.widget.instance.settings.pricePlans],
                daysWithNoSales: $scope.widget.instance.settings.daysWithNoSales
            };

            var promise = $http({
                url: ('api/subscribers/{0}/margins/components').format(subscriberId),
                method:'POST',
                data: config
            })
            .then(function(res) {
                $scope.graphData.indexPrice = [];
                $scope.graphData.unitCost = [];
                $scope.graphData.unitPrice = [];
                $scope.graphData.isMonthOnly = false;

                res.data.forEach(function(item) {
                    if(item.CalendarYearMonth) {
                        $scope.graphData.isMonthOnly = true;
                    }

                    var maxi = maxNum(item);
                    var indexPrice = item.IndexPrice || item.IndexPriceAverage;
                    var unitCost = item.UnitCostAverage;
                    var unitPrice = item.UnitPriceAverage;
                    var date = moment(item.CalendarDate || item.CalendarYearMonth).format("MMM DD, YYYY");
                    var margin = item.UnitPriceAverage - item.UnitCostAverage;
                    var indexData = { data: indexPrice, dateData: date, type: 'Index' };
                    var unitCostData = { data: unitCost, dateData: date, margin: margin.toFixed(4), type: 'Unit Cost' };
                    var unitPriceData = { data: unitPrice, dateData: date, margin: margin.toFixed(4), type: 'Unit Price' };

                    $scope.graphData.maxNum = maxi;
                    $scope.graphData.indexPrice.push(indexData);
                    $scope.graphData.unitCost.push(unitCostData);
                    $scope.graphData.unitPrice.push(unitPriceData);

                    $scope.graphData.indexPrice.sort(function(a, b) { return new Date(a.dateData).getTime() - new Date(b.dateData).getTime() });
                    $scope.graphData.unitCost.sort(function(a, b) { return new Date(a.dateData).getTime() - new Date(b.dateData).getTime() });
                    $scope.graphData.unitPrice.sort(function(a, b) { return new Date(a.dateData).getTime() - new Date(b.dateData).getTime() });
                });

                if($scope.graphData.unitCost.length == 0) {
                    $scope.nodata = true;
                }

            });

            return $scope.widget.promises.monitor(promise);
        }

    }

]);