angular.module( 'angus.controllers' ).controller( 'paygoAdminBillingCtrl', [
	'$scope', '$q', 'fluentRest', 'moment', 'actionViews', '_',
	function( $scope, $q, fluentRest, moment, actionViews, _ ) {
		'use strict';

		function getDealers() {
			return fluentRest
                .api()
                .subscribers()
                .dealers()
                .post()
                .then(function(dealers) {
                    return dealers;
                });
		}				

		function stripSubscribersFromDealers(dealers){ 
			return _.map(dealers, function(dealer){ return dealer.subscriberId;
			})}

		function flattenDealers(dealers){
			return _.flatten(dealers); 
		}

		function setDealers(dealers){ 
			$scope.dealers = dealers;  
			return $scope.dealers;
		}

		function countDealersInRange(dealers){			
			var futureDate = moment().add($scope.days, 'days');
			var today = moment();			

			$scope.dealers = _.reduce(dealers, function(result, dealer){
				var nextBillDate = moment(dealer.nextBillDate);

				if(nextBillDate.isBefore(futureDate)  && nextBillDate.isAfter(today)){
					result.future.count++;
					result.future.ids.push(dealer.id);
				}
				else if(nextBillDate.isBefore(today)){
					result.past.count++;
					result.past.ids.push(dealer.id);
				}
				else {
					result.today.count++;
					result.today.ids.push(dealer.id);
				}

				return result;
			}, {
				future : {
					count : 0,
					ids : []
				},
				today: {
					count : 0,
					ids : []
				},
				past : {
					count : 0,
					ids : []
				}
			});
		}

		function getDealerCountsForSubscribers(subscriberIds){

			var upcomingBills = _.map(subscriberIds, function(subscriberId){
				return fluentRest.api().subscribers(subscriberId).bills().upcoming().get();	
			});

			return $q
					.all(upcomingBills)
					.then(flattenDealers);
		}

		function init(){

			$scope.upcomingBillOptions = [{days: 1},{days: 2},{days: 3},{days: 4},{days: 5},{days: 6},{days: 7},{days: 8},{days: 9},{days:10}];			
			$scope.days = 7;
			
			var promise = getDealers()
							.then(stripSubscribersFromDealers)
							.then(getDealerCountsForSubscribers)
							.then(setDealers)
							.then(countDealersInRange);

			return $scope.widget.promises.monitor(promise);
		}

        $scope.widget.setDefaultInitialization(init);

		$scope.upcomingBillOptionsChanged = function(){
			countDealersInRange();
		}
     	
		$scope.openDealers = function(ids){
			$scope.widget.openDetailView( actionViews.getActionView( $scope.widget.code ), {
				selectedDealers : ids
			} );
		};	

	}
]);

