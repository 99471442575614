angular.module( 'angus.controllers' )
.controller( 'paygoAdminBillingActionViewCtrl', [
	'$scope', '$q', 'actionViewTemplates', 'fluentRest', 'promiseMonitor', 'datePickerService', 'modalService', 'constantsService', 'Paging', 'dateRange','$filter', 'csvService', '_', 'moment',
	function( $scope, $q, actionViewTemplates, fluentRest, promiseMonitor, datePickerService, modalService, constants, paging, dateRange, $filter, csvService, _, moment ) {
		'use strict';

		var Dealers = $scope.Dealers = new function(){

			this.get = function() {
				return fluentRest
                    .api()
                    .subscribers()
                    .dealers()
                    .post()
                    .then(function(dealers) {
                        return dealers;
                    });
			};

			this.set = function(dealers){
				$scope.dealers = dealers;
				$scope.selected = { dealerIds : _.map(dealers, 'id') };
			};

			this.bySubscriber = function(dealerIds){

				var dealers = _.filter($scope.dealers, function(dealer){
					return _.some(dealerIds, function(dealerId){
						return dealerId == dealer.id;
					});
				});

				return _.chain(dealers)
						.groupBy('subscriberId')
						.map(function(subscriberDealers, subscriberId){
							return {
								dealers 	 : subscriberDealers,
								dealerIds 	 : _.map(subscriberDealers, 'id'),
								id : subscriberId
							};
						})
						.value();
			};
		};

		var Bills = $scope.Bills = new function(){

			var defaultStart = moment().utc().subtract(6, 'months').toDate();
			var deafultEnd 	 = moment().utc().toDate();

			this.processDates = new dateRange(defaultStart, deafultEnd);
			this.invoiceDates = new dateRange(defaultStart, deafultEnd);


			this.columns = {
				dealer : 'dealer',
	    		invoiceDate : 'invoiceDate',
	    		processDate : 'processDate',
	    		type : 'type',
	    		status : 'status',
	    		consumerPrice : 'consumerPrice',
	    		totalUnits : 'totalUnits',
	    		intacct : 'intacctReferenceId',
	    		invoiceId : 'id',
	    		bosIntegrationStatus : 'bosIntegrationStatus.name'
			};

			this.ascending 	= false;
			this.sortBy 	= this.columns.processDate;
			this.paging 	= new paging(25);
			this.showVoided = false;

			this.Monitor 	= new promiseMonitor();



			this.get = function(subscribers, pageNumber){
				var that = this;
				var dealerIds = [];

				_.forEach(subscribers, function(subscriber){
					var ids = _.filter(subscriber.dealerIds, function(subscriberDealerId){
						return _.some($scope.selected.dealerIds, function(dealerId){
							return subscriberDealerId == dealerId;
						});
					});
					dealerIds = dealerIds.concat(ids);
				});

				var params = {
					dealerIds 			 : dealerIds,
					billProcessStartDate : this.processDates.start,
					billProcessEndDate 	 : this.processDates.end,
					invoiceStartDate 	 : this.invoiceDates.start,
					invoiceEndDate 		 : this.invoiceDates.end,
					billTypeIds			 : this.Types.getIds(),
					sortBy				 : this.sortBy,
					ascending			 : this.ascending,

				};

				if(this.showVoided) params.includeVoided = true;

				var url = fluentRest
							.api()
							.subscribers()
							.bills()
							.toString();

				var promise = this.paging.postGetPage(url, pageNumber, params);

				Bills.Monitor.monitor(promise);

				return promise;

			};

			this.set = function(bills){
				$scope.billSummaries = bills;
			};



			this.mapDealers = function(bills){
				return  _.map(bills, function(bill){
					bill.dealer =  _.find($scope.dealers, function(dealer){ return dealer.id == bill.dealerId; } );
					return bill;
				});
			};


			this.generateForSubscriber = function(dealerIds, subscriberId, processDate, invoiceDate){
				var data = {
					dealerIds 	: dealerIds,
					processDate : processDate,
					invoiceDate : invoiceDate
				};

	        	return fluentRest
						.api()
						.subscribers(subscriberId)
						.bills()
							.post(data);
	        };

	        this.approve = function(bill){
	        	if(!bill)
	        		return;

	        	bill.isPending = true;
	        	return fluentRest
	        			.api()
	        			.subscribers(bill.dealer.subscriberId)
	        			.bills(bill.id)
	        			.approve()
	        				.post()
	        				.then(function(){
	        					bill.status = $scope.billStatuses.approved.value.display;
	        					bill.isPending = false;
	        				});
	        };

	        this.void = function(bill){
	        	if(!bill)
	        		return;

	        	bill.isPending = true;
	        	var that = this;
	        	return fluentRest
	        			.api()
	        			.subscribers(bill.dealer.subscriberId)
	        			.bills(bill.id)
	        			.void()
	        				.post()
	        				.then(function(){
	        					if(that.showVoided){
	        						bill.status = $scope.billStatuses.void.value.display;
	        						bill.isPending = false;
	        					}
	    						else{
	    							_.remove($scope.billSummaries, function(b){
	    								return b.id == bill.id;
	    							});
	    						}
	        				});
        	};

			this.Statuses = new function(){
				this.get = function(){
					var deferred = $q.defer();
					deferred.resolve(constants.billStatus.enums);
					return deferred.promise;
				};

				this.set = function(){
					$scope.billStatuses = constants.billStatus;
				};
			};

			this.Types = new function(){
				this.get = function(){
					var deferred = $q.defer();

					var types =  _.map(constants.billType.enums, function(type){
						return {
							display : type.value.display,
							id : type.value.id,
							isSelected: true
						};
					});

					deferred.resolve(types);

					return deferred.promise;
				};

				this.set = function(billTypes){
					$scope.billTypes  = billTypes;
				};

				this.getIds = function(){
					return _.chain($scope.billTypes)
							.filter('isSelected')
							.map('id')
							.value();
				};
			};

			this.changeDates = function(bill){
				if(!bill) return;

				var that = this;

				var scope = {
					invoiceDate : moment(moment.utc(bill.invoiceDate).format('YYYY-MM-DD')).toDate(),
					processDate : moment(moment.utc(bill.processDate).format('YYYY-MM-DD')).toDate(),
					dateOptions : $scope.dateOptions,
					processDateOpened : false,
					invoiceDateOpened : false,
					openProcess : function($event){
						stopEvent($event);
		    			this.invoiceDateOpened = false;
		    			this.processDateOpened = true;
					},
					openInvoice : function($event){
						stopEvent($event);
		    			this.invoiceDateOpened = true;
		    			this.processDateOpened = false;
					},
				};

				modalService
					.openTopBarModal( actionViewTemplates.paygoAdminBillingDates, scope)
					.spread(function(processDate, invoiceDate){
						if(!invoiceDate || !processDate) return;

			        	return fluentRest
			        			.api()
			        			.subscribers(bill.dealer.subscriberId)
			        			.bills(bill.id)
			        			.dates()
			        				.put({ invoiceDate : invoiceDate, processDate : processDate})
			        				.then(function(){
			        					bill.invoiceDate = invoiceDate;
			        					bill.processDate = processDate;
			        					bill.isPending   = false;
			        				});
					});
			};

			this.Details = new function(){

				this.columns = {
					bosCustomerId : 'bosCustomerId',
					container : 'containerDisplay',
					customer : 'customerName',
			    	date : 'deliveryDate',
			    	unitPrice : 'unitPrice',
			    	units : 'units',
			    	type : 'usageType',
			    	total : 'total',
	    			bosIntegrationStatus : 'bosIntegrationStatus.name'
				};



				this.get = function(bill, pageNumber){
					bill.paging 	 = bill.paging || new paging(25);
					bill.ascending 	 = bill.ascending || false;
					bill.sortBy 	 = bill.sortBy || this.columns.date;
					bill.Monitor     = bill.Monitor || new promiseMonitor();

					var params = {
						sortBy : bill.sortBy,
						ascending : bill.ascending
					};

					var url = fluentRest
								.api()
								.subscribers(bill.dealer.subscriberId)
								.bills(bill.id)
								.lines()
									.toString();

					return bill.paging.getPage(url, pageNumber, params);
				};



				this.void = function(bill, billLine){
					if(!bill|| !billLine) return;

					billLine.isPending = true;

					return fluentRest
		        			.api()
		        			.subscribers(bill.dealer.subscriberId)
		        			.bills(bill.id)
		        			.lines(billLine.id)
		        			.void()
		        				.post()
		        				.then(function(){
		        					billLine.usageStatus = $scope.billStatuses.void.value.display;
		        					billLine.isPending = false;
		        					bill.totalUnits = bill.totalUnits - billLine.units;
		        					bill.consumerPrice = bill.consumerPrice - billLine.total;
		        					bill.dealerPrice = bill.dealerPrice - billLine.total;
		        				});
		    	}
			};

		};

		var Customer = $scope.Customer = new function(){

			function getCustomerById(id, dealerId){
				return fluentRest
						.api()
						.subscribers($scope.activeTab.bill.dealer.subscriberId)
						.customerAccounts(id)
						.paygo()
						.get({
							dealer: dealerId
						});
			}

			this.open = function(customerId, containerId, dealerId){

				getCustomerById(customerId, dealerId)
					.then(function(customer){
						if(customer){

						var widget, template; 
						if(!$scope.subscriberId){
							widget 	 = constants.widgets.paygoAdminCustRec;
							template = actionViewTemplates.paygoAdminCustRec;
						} else{
							widget = constants.widgets.paygoCustomers;
							template = actionViewTemplates.paygoCustRec;
						} 

						var scope = {
							widgetName : 'Paygo Customer Records',
							widgetIcon : 'group',
							widgetId   : widget.value.id,
							widgetCode : widget.value.code,
							model      : { 
								customer : customer, 
								containerId : navigateToContainer ? usage.customer.tankNavigationId : null								
							}
						};

						modalService
							.openActionView( template, scope, widget.value.id );
					}
					});

			};
		};


		$scope.switchTab = function(tab){
			$scope.activeTab = tab;
		};

		$scope.closeTab = function(tab){
			_.remove($scope.customTabs, function(t){
				return t == tab;
			})

			$scope.activeTab = $scope.viewBillsTab;
		};


    	function stopEvent($event){
    		$event.preventDefault();
            $event.stopPropagation();
    	}

		function init(){
			$scope.generateBillsTab = {name : 'generate'};
			$scope.viewBillsTab = {name : 'view'};
			$scope.activeTab = $scope.generateBillsTab;
			$scope.initMonitor = new promiseMonitor();
			$scope.generateMonitor = new promiseMonitor();
			$scope.today = moment.utc().toDate();

			$scope.generate= {
        		filters : {
		        	processDateOpened : false,
		        	invoiceDateOpened : false,
		        	openProcessDate   : function($event){
		            	stopEvent($event);
		            	this.invoiceDateOpened = false;
		            	this.processDateOpened = true;
		        	},
		        	openInvoiceDate   : function($event){
		            	stopEvent($event);
		            	this.invoiceDateOpened = true;
		            	this.processDateOpened = false;
		        	},
		        	processDate : moment().utc().toDate(),
	        		invoiceDate : moment().utc().toDate(),
	        		dealerIds : []
		        }
	        };

			var promise = $q.all([
				Dealers
					.get()
					.then(Dealers.set),
				Bills.Statuses
					.get()
					.then(Bills.Statuses.set),
				Bills.Types
					.get()
					.then(Bills.Types.set)
			])
			.then(function(){
				return queryBills();
			});

			$scope.initMonitor.monitor(promise);


		}

		init();


		function queryBills(pageNumber){

			var subscribers = Dealers.bySubscriber($scope.selected.dealerIds);

			return Bills.get(subscribers, pageNumber)
						.then(function(bills){
							Bills.mapDealers(bills.items);
							Bills.set(bills.items);
						});
		}

		$scope.getBills = queryBills;

		$scope.applyFilters = function(){
			queryBills();
		};

        $scope.generateBills = function(){

        	if($scope.generateMonitor.isPending()) return;

			var originalNewBillCount = _.filter($scope.billSummaries, function(bill){ return bill.status == 'New'}).length;

			if(!($scope.generate.filters.processDate && $scope.generate.filters.invoiceDate))
				return;

			if( $scope.generate.filters.dealerIds.length === 0 ) return;

			var subscriberPromises 	= 	_.map(Dealers.bySubscriber($scope.generate.filters.dealerIds), function(subscriber){


																				var ids = _.filter(subscriber.dealerIds, function(subscriberDealerId){
																					return _.some($scope.generate.filters.dealerIds, function(dealerId){
																						return subscriberDealerId == dealerId;
																					});
																				});

					        													return Bills.generateForSubscriber(ids,
					        																					   subscriber.id,
					        																					   $scope.generate.filters.processDate,
					        																					   $scope.generate.filters.invoiceDate);
					        												 });

			var promise = $q.all(subscriberPromises)
					 .then(function(){
						return queryBills();
					})
					.then(function(){
						var newBillCount =_.filter($scope.billSummaries, function(bill){ return bill.status == 'New'}).length;
						var msg;

						if(originalNewBillCount == newBillCount)
							msg = 'No bills were created. Make sure there is unbilled usage and try again.';
						else if(originalNewBillCount < newBillCount){
							var newBills = newBillCount - originalNewBillCount;
							msg = ('{0} bill{1} created.').format(newBills, newBills === 1 ? ' was' : 's were' );
						}
						if(msg){
							if(!$scope.alerts) $scope.alerts = [];
							$scope.alerts.push({
	                        	type: 'info',
	                        	msg: msg
	                    	});
						}
					});


        	$scope.generateMonitor.monitor(promise);

        	return promise;
        };

        $scope.closeAlert = function(index) {
            $scope.alerts.splice(index, 1);
        };

        $scope.viewBill = function(bill, dealerId){
        	if(!$scope.customTabs) $scope.customTabs = [];


        	var customTab = _.find($scope.customTabs, function(tab){
        		return tab.bill == bill;
        	});

        	if(!customTab){
        		customTab = {
        			bill : bill
        		};

        		$scope.customTabs.push(customTab);

        		bill.initMonitor = bill.initMonitor || new promiseMonitor();

        		bill.initMonitor.monitor($scope.getBillDetails(bill, null, dealerId));

        	}

        	$scope.activeTab = customTab;

        };


		$scope.getBillDetails = function(bill, pageNumber, dealerId){
			var promise = Bills.Details.get(bill, pageNumber)
							.then(function(details){
								_.forEach(details.items, function(detail){
	                            	detail.containerDisplay = detail.containerName || detail.containerId;
	                            	detail.dealerId = dealerId;
	                        	});

								bill.details = details.items;
							});

			bill.Monitor.monitor(promise);

			return promise;
		};



		$scope.sortDetailsBy= function(bill, sortBy){
			if(bill.Monitor.isPending())
				return;

			if(bill.sortBy == sortBy)
				bill.ascending = !bill.ascending;

			bill.sortBy = sortBy;

			$scope.getBillDetails(bill);
		}

		$scope.sortBillsBy= function(sortBy){

			if(Bills.Monitor.isPending())
				return;


			if(Bills.sortBy == sortBy)
				Bills.ascending = !Bills.ascending;

			Bills.sortBy = sortBy;


			$scope.applyFilters();
		}

        $scope.integrateToIntacct = function(bill){
	     	if(!bill)
	     		return;


			var data = {
				intacctId : bill.dealer.intacctId,
				processDate : bill.processDate,
				invoiceDate : bill.invoiceDate,

			};

	     	bill.isPending = true;
	     	return fluentRest
	     			.api()
	     			.subscribers(bill.dealer.subscriberId)
	     			.bills(bill.id)
	     			.integrateToIntaact()
	     				.post(data)
	     				.then(function(data){
	     					bill.isPending = false;
	     					bill.intacctReferenceId = data.intacctReferenceId;
	     				});
	     };

	     $scope.stage = function(bill){
	     	if(!bill)
	     		return;

	     	bill.isPending = true;
	     	return fluentRest
	     			.api()
	     			.subscribers(bill.dealer.subscriberId)
	     			.bills(bill.id)
	     			.stage()
	     				.post()
	     				.then(function(){
	     					bill.isPending = false;
	     					bill.bosIntegrationStatus.name = 'Staged';
	     				});
	     };

	    $scope.downloadInProgress = csvService.downloadInProgress;

	    $scope.exportBill = function(bill){

	    	bill.Monitor = bill.Monitor || new promiseMonitor();


	    	if(bill.Monitor.isPending())
	    		return;

	    	bill.isPending = true;

			var url = fluentRest
						.api()
						.subscribers(bill.dealer.subscriberId)
						.bills(bill.id)
						.lines()
							.toString();

			var name = ('{0} ( {1} )').format(bill.dealer.name, moment(bill.processDate).format('l'));

			var promise = csvService
							.getCsv(url, {}, name)
							.then(function(){
								bill.isPending = false;
							});

        	bill.Monitor.monitor(promise);

	    };
	}
]);

