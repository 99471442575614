angular.module('angus.controllers').controller('paygoPeriodAccountingCtrl', [
	'$scope', 'fluentRest', '$q', 'promiseMonitor', '_', 'moment', 'Paging', 'csvService', 'modalService', 'actionViewTemplates', 'dateRange',
	function($scope, fluentRest, $q, promiseMonitor, _, moment, paging, csvService, modalService, actionViewTemplates, dateRange) {
		'use strict';

		function getDealers(subscriberId){
			return fluentRest
					.api()
					.subscribers(subscriberId)
					.dealers()
					.get();
		}

		function getParams(includePaging){
			var params = {
				dealerIds 	: $scope.selected.dealers,
				startDate   : $scope.selected.dates.start,
				endDate 	: $scope.selected.dates.end
			};

			if(includePaging)
				params.ascending = $scope.ascending;
				params.sortBy = $scope.sortBy;

			return params;
		}

		function getApiEndpoint(){
			return fluentRest
					.api()
					.subscribers()
					.accounting();
		}

		$scope.getPeriodAccounting = function(pageNumber){
			if($scope.pageMonitor.isPending())
				return;

			var params = getParams(true);

			var url = getApiEndpoint().toString();

			var promise =  $scope.paging.postGetPage(url, pageNumber, params)
								.then(function(rows){
									$scope.rows = rows.items;
								});

			$scope.pageMonitor.monitor(promise);

			return promise;
		}


		function init(){
			$scope.promiseMonitor = new promiseMonitor();
			$scope.pageMonitor    = new promiseMonitor();

			$scope.today = moment.utc().toDate();

			$scope.paging = new paging(25);
			$scope.ascending = true;
			$scope.sortBy = 'delivery.date';

			$scope.selected = {
				dates : new dateRange(moment.utc().subtract(1, 'years'), moment.utc())
			};

			var promise = $q
				.all([
					getDealers($scope.subscriberId)
						.then(function(dealers){
							$scope.dealers = dealers;
							$scope.selected.dealers = _.map($scope.dealers, 'id');
						})
					])
				.then(function(){
					return $scope.getPeriodAccounting();
				});

			$scope.promiseMonitor.monitor(promise);
		}

		$scope.sort = function(sortBy){
			if($scope.sortBy == sortBy)
				$scope.ascending = !$scope.ascending;
			else {
				$scope.ascending = true;
				$scope.sortBy = sortBy;
			}

			return $scope.getPeriodAccounting(1);
		}

		function getCustomerById(id, subscriberId){
			return fluentRest
				.api()
				.subscribers(subscriberId)
				.customerAccounts(id)
				.paygo()
				.get();
		}

		$scope.openCustomer = function(row, navigateToContainer){

			getCustomerById(row.customer.id, row.dealer.subscriberId)
				.then(function(customer){
					if(customer){

						var widget, template; 
						if(!$scope.subscriberId){
							widget 	 = constants.widgets.paygoAdminCustRec;
							template = actionViewTemplates.paygoAdminCustRec;
						} else{
							widget = constants.widgets.paygoCustomers;
							template = actionViewTemplates.paygoCustRec;
						} 

						var scope = {
							widgetName : 'Paygo Customer Records',
							widgetIcon : 'group',
							widgetId   : widget.value.id,
							widgetCode : widget.value.code,
							model      : { 
								customer : customer, 
								containerId : navigateToContainer ? usage.customer.tankNavigationId : null								
							}
						};

						modalService
							.openActionView( template, scope, widget.value.id );
					}
				});

		};

		 $scope.downloadInProgress = csvService.downloadInProgress;

		 $scope.toCsv = function(){
        	var params = getParams();
			var url = getApiEndpoint().toString();

        	csvService
        		.postGetCsv(url, params, 'Billing Period Accounting Report (' + moment().format('l') + ').csv');
        };



		init();
	}
]);
