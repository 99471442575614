angular.module('angus.directives').directive('paygoAdminBilling',
    [
        '$document',
        function ($document) {
            return {
                scope: {
                    closeCb: "=",
                    actionModel: "="
                },
                controller: function ($scope) {
                    $scope.selectedDealers = $scope.actionModel ? $scope.actionModel.selectedDealers : null;

                },
                templateUrl: 'templates/widgets/paygoAdminBilling/html/actionViews/paygoAdminBillingActionView.html',
                link: function (scope, element, attrs, ctrl) {
                }
            };
        }
    ]);