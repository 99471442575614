angular.module( 'angus.directives' ).directive( 'paygoBillingHistory', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		return {
			scope: {
				closeCb: "=",
				model: "=actionModel"				
			},
			controller : function($scope){
				$scope.subscriberId = $scope.model.subscriberId;
			},
			templateUrl: actionViewTemplates.paygoBillingHistoryContent
		};
	}
] );