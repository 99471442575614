angular.module('angus.controllers').controller('paygoDealerPriceEntryCtrl', [
    '$scope', 'fluentRest', '$http', '$q', 'promiseMonitor', '_', 'moment', 'Paging', 'csvService', 'modalService', 'actionViewTemplates', 'constantsService',
    function($scope, fluentRest, $http, $q, PromiseMonitor, _, moment, Paging, csvService, modalService, actionViewTemplates, constants) {
        'use strict';

    	//var apiUrl = 'api/subscribers/deliveries/unitprices';

        function getDealers(subscriberId) {
            return fluentRest
                .api()
                .subscribers(subscriberId)
                .dealers()
                .get({
                    ascending: 1
                });
        }

        function buildQueryParams() {
            var params = {
                dealerIds     : $scope.selected.dealers,
                productIds 	  : $scope.selected.products,
                startDate     : moment.utc($scope.dateRange.start).format(),
                endDate       : moment.utc($scope.dateRange.end).format(),
                zeroPriceOnly : $scope.zeroPriceOnly
            };


            params.ascending = $scope.ascending;
            params.sortBy    = $scope.sortBy;


            return params;
        }

        function init() {
            $scope.PromiseMonitor = new PromiseMonitor();
            $scope.pageMonitor    = new PromiseMonitor();

            $scope.paging           = new Paging(50);
            $scope.ascending        = false;
            $scope.sortBy           = 'deliveryDate';
            $scope.dealerUnitPrices = [];
            $scope.alerts           = [];


            $scope.dateRange = {
                start : moment.utc().subtract(30, 'days').toDate(),
                end   : moment.utc().toDate()
            };

            $scope.today = moment.utc().toDate();

            $scope.products = _.map(constants.PaygoProductCategory.enums, function(cat) {
                return {
                    id   : cat.value.id,
                    name : cat.value.display
                };
            });

            $scope.selected = {};
            $scope.selected.products = _(constants.PaygoProductCategory.enums).map('value').map('id').value();
            var promise = $q.all([
                    getDealers($scope.subscriberId)
                        .then(function(dealers) {
                            $scope.dealers = dealers;
                            //$scope.selected.dealers = _.map($scope.dealers, 'id');
                        })
                ]);
                // .then(function() {
                //     return $scope.getDealerUnitPrices();
                // });

            $scope.PromiseMonitor.monitor(promise);
        }

        $scope.getDealerUnitPrices = function(pageNumber) {
            if ($scope.pageMonitor.isPending())
                return;

            var url = fluentRest.api().subscribers().deliveries().unitprices().toString();


            var promise = $scope.paging.postGetPage(url, pageNumber, buildQueryParams(true))
                .then(function(dealerUnitPrices) {
                    $scope.dealerUnitPrices = dealerUnitPrices.items;
                    _.forEach($scope.dealerUnitPrices, function(unitPrice){
                        unitPrice.editDealerUnitPrice = unitPrice.dealerUnitPrice;
                    });
                });

            $scope.pageMonitor.monitor(promise);
        }

        $scope.sort = function(sortBy) {
            if ($scope.sortBy == sortBy) {
                $scope.ascending = !$scope.ascending;
            } else {
                $scope.ascending = true;
                $scope.sortBy = sortBy;
            }

            return $scope.getDealerUnitPrices(1);
        }

        function stopEvent($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }


        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        $scope.startDateOpened = false;
        $scope.endDateOpened = false;


        $scope.closeAlert = function(index) {
            $scope.alerts.splice(index, 1);
        };

        $scope.unitPricesChangedAndValid = function(){
            return _.some($scope.dealerUnitPrices, function(unitPrice){
                return unitPrice.editDealerUnitPrice != unitPrice.dealerUnitPrice && (unitPrice.editDealerUnitPrice || unitPrice.editDealerUnitPrice === 0)
            })
        }

		$scope.saveUnitPrices = function(unitPrices) {

            unitPrices = unitPrices || $scope.dealerUnitPrices;

            var data =_.reduce(unitPrices, function(result, unitPrice){
                _.forEach(unitPrice.layers, function(layer){
                    var newUnitPrice;

                    if((layer.editDealerUnitPrice || layer.editDealerUnitPrice === 0) && layer.editDealerUnitPrice != layer.dealerUnitPrice)
                        newUnitPrice = layer.editDealerUnitPrice;
                    else if((unitPrice.editDealerUnitPrice || unitPrice.editDealerUnitPrice === 0) && unitPrice.editDealerUnitPrice != unitPrice.dealerUnitPrice)
                        newUnitPrice = unitPrice.editDealerUnitPrice;

                    if(newUnitPrice || newUnitPrice === 0){
                        if(!result[unitPrice.subscriberId]) result[unitPrice.subscriberId] = [];
                        result[unitPrice.subscriberId].push({
                            unitPrice : newUnitPrice,
                            layerId   : layer.id
                        });
                    }
                });
                return result;
            }, {});

            var promise = fluentRest
                .api()
                .subscribers()
                .deliveries()
                .unitprices()
                .put(data)
                    .then(function(){
                        $scope.alerts.push({
                            type: 'success',
                            msg: '<strong>Success!</strong> The dealer unit prices have been updated.'
                        });

                        return $scope.getDealerUnitPrices();
                    });

            $scope.unitPriceSavePromises = new PromiseMonitor(promise);
        }

        function getCustomerById(subscriberId, customerId) {
            return fluentRest
                .api()
                .subscribers(subscriberId)
                .customerAccounts(customerId)
                .paygo()
                .get();
        }

        $scope.openCustomer = function(layer, andContainer) {

            getCustomerById(layer.subscriberId, layer.customerId)
                .then(function(customer) {
                    if (customer) {
                        var scope = {
                            widgetName: 'Paygo Customer Records - Administrator',
                            widgetIcon: 'group',
                            widgetId: constants.widgets.paygoAdminCustRec.value.id,
                            widgetCode: constants.widgets.paygoAdminCustRec.value.code,
                            model: {
                                customer: customer,
                                containerId: andContainer ? layer.containerId : null
                            }
                        };

                        modalService
                            .openActionView(actionViewTemplates.paygoAdminCustRec, scope, constants.widgets.paygoAdminCustRec.value.id);
                    }
                });

        };

        $scope.byLayer = function(unitPrice){
            var editUnitPrice = _.cloneDeep(unitPrice)

            _.forEach(editUnitPrice.layers, function(layer){
                layer.editDealerUnitPrice = layer.dealerUnitPrice;
            });

            var scope = {
                unitPrice : editUnitPrice,
                openCustomer : function(layer, andContainer){
                    $scope.openCustomer(layer, andContainer);
                    this.close();
                }
            };

            modalService
                .openTopBarModal(actionViewTemplates.paygoDealerByLayer, scope)
                .then(function(newUnitPrices){
                    if(!newUnitPrices) return;
                    $scope.saveUnitPrices([newUnitPrices]);
                });

        }

        $scope.openStartDate = function($event) {
            stopEvent($event);
            this.endDateOpened = false;
            this.startDateOpened = true;
        };
        $scope.openEndDate = function($event) {
            stopEvent($event);
            this.startDateOpened = false;
            this.endDateOpened = true;
        };
        $scope.resetEndDate = function() {
            if (moment(this.dateRange.end).isBefore(moment(this.dateRange.start)))
                this.dateRange.end = this.dateRange.start;
        };

        $scope.downloadInProgress = csvService.downloadInProgress;

        $scope.toCsv = function() {
            var url = fluentRest.api().subscribers().deliveries().unitprices().toString();
            csvService.postGetCsv(url, buildQueryParams(), 'Dealer Unit Prices (' + moment().format('l') + ').csv');
        };

        init();
    }
]);
